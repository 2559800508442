import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LocationIcon from "@material-ui/icons/Place";
import GroupIcon from "@material-ui/icons/Group";
import ModelDialog from "components/ModelDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { useWidth } from "index";
import { Context as AlertContext } from "context/AlertContext";
import { Context as ModelCOntext } from "context/ModelContext";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import VotingModal from "components/VotingModal/VotingModal";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    height: "100%",
  },
  media: {
    height: "30vh",
    backgroundPosition: "top center !important",
    padding: "50px",
  },

  info: {
    color: "#3C4852",
    display: "flex",
    alignItems: "flex-start",
    fontWeight: 400,
    // paddingLeft:'8px',
  },
  tooltipStyle: {
    fontSize: "16px",
  },
}));

export default function ProductCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const width = useWidth();
  const { showAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { state } = useContext(ModelCOntext);
  const [model, setModel] = useState({});

  useEffect(() => {
    setModel(state);
  }, [state]);

  useEffect(() => {
    setModel(props.model);
  }, []);

  return (
    <>
      <Card className={classes.root} elevation={3}>
        <ModelDialog
          enableVote={props.enableVote}
          model={model}
          setOpen={setIsDialogOpen}
          open={isDialogOpen}
        />
        <CardActionArea onClick={() => setIsDialogOpen(true)}>
          <CardMedia
            image={`${process.env.REACT_APP_API_ENDPOINT}/api/image/${
              model.contact_id
            }?q=${Math.random()}`}
            className={classes.media}
          />

          <CardContent style={{ padding: "8px" }}>
            <Box>
              <Box my={2} pl={1}>
                <Typography
                  variant="h5"
                  style={{ color: "#3C4852", fontWeight: "bold" }}
                >
                  {model.name}
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.info}>
                <GroupIcon style={{ marginLeft: "5px", paddingRight: "5px" }} />{" "}
                {model.friendly_name}
              </Typography>
              <Typography variant="h6" className={classes.info}>
                <LocationIcon
                  style={{ marginLeft: "5px", paddingRight: "5px" }}
                />{" "}
                {model.state}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <Box width="100%">
          <Divider />
        </Box>
        <CardActions>
          <Box
            display="flex"
            justifyContent={props.enableVote ? "space-between" : "center"}
            alignItems="center"
            width="100%"
            px={1}
          >
            <Tooltip
              title={`${model.total_votes} Votes`}
              classes={{ tooltip: classes.tooltipStyle }}
            >
              <Typography
                component="span"
                variant="h4"
                style={{ color: "#3C4852" }}
              >
                {`${numeral(model.total_votes).format("0,0")}`}
              </Typography>
            </Tooltip>
            {props.enableVote && (
              <VotingModal modelId={model.contact_id} />

              // <Button
              //   startIcon={<VoteIcon />}
              //   variant='contained'
              //   size='large'
              //   color='primary'
              //   onClick={handleVote}
              //   disabled={isLoading}
              // >
              //   Vote
              //   {isLoading && (
              //     <CircularProgress
              //       style={{ position: 'absolute' }}
              //       color='primary'
              //       size={24}
              //     />
              //   )}
              // </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </>
  );
}
