import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import qs from "qs";
import axios from "axios";
import numeral from "numeral";
import VoteIcon from "@material-ui/icons/HowToVote";
import PaymentForm from "components/PaymentForm/PaymentForm";
import { TextField, Box, Link } from "@material-ui/core";

export default function VotingModal({ modelId }) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentToken, setPaymentToken] = useState("");
  const [amount, setAmount] = useState(1);

  const handleClickOpen = () => {
    console.log("modelId", modelId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVote = async () => {
    try {
      setIsLoading(true);
      const query = {
        id: modelId,
        amount,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/vote?${qs.stringify(query)}`
      );
      console.log("response", response);
      if (response) {
        setPaymentToken(response.data.token);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PaymentForm token={paymentToken} />
      <Button
        startIcon={<VoteIcon />}
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClickOpen}
      >
        Vote
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="vote-dialog-title"
        aria-describedby="vote-dialog-description"
      >
        <DialogTitle id="vote-dialog-title">{"Voting Form"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that the charged amount will be calculated as ($1 per
            vote). By voting with this system you are agreeing to the following{" "}
            <Link
              href="https://www.unitedstatescrown.com/terms"
              target="_blank"
            >
              terms and conditions
            </Link>
            .
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="votes"
            variant="outlined"
            label="Votes"
            value={amount}
            type="number"
            onChange={(e) => {
              let value = parseInt(e.target.value);
              // if (value >= 1)
              setAmount(value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            size="large"
            variant="text"
          >
            Close
          </Button>
          <Button
            onClick={handleVote}
            disabled={isLoading || !amount}
            variant="contained"
            size="large"
            color="primary"
          >
            {`Pay ${numeral(amount).format("$0,0")}`}
            {isLoading && (
              <CircularProgress
                style={{ position: "absolute" }}
                color="primary"
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
