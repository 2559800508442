import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { Context as DivisionsContext } from "context/DivisionsContext";
import { Context as AlertContext } from "context/AlertContext";

import { Context as StateContestantContext } from "context/StateContestantContext";
import { Paper, Container, CircularProgress, Link } from "@material-ui/core";

import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Choose the state and contestant intake", "Choose the division"];
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const { getDivisions, state: APIdivisions } = useContext(DivisionsContext);
  const { showAlert } = useContext(AlertContext);

  const { getStatesContestants, state: statesContestants } = useContext(
    StateContestantContext
  );
  // const [divisions, setDivisions] = useState([]);
  const [state, setState] = useState("");
  const [contestant, setContestant] = useState("");
  const [isNational, setIsNational] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  console.log("APIdivisions", APIdivisions);

  async function asyncGetDivisions() {
    const result = await getDivisions(contestant, state);
    if (result) {
      if (result.error) {
        if (result.msg) {
          // showAlert('error', result.msg);
        }
      } else {
        // showAlert('error', 'Empty');
      }
    }
  }

  async function asyncGetStatesContestants() {
    const result = await getStatesContestants();
    if (result) {
      if (result.error) {
        if (result.msg) {
          // showAlert('error', result.msg);
        }
      } else {
        // console.log('statesContestants', statesContestants);
      }
    }
  }

  useEffect(() => {
    asyncGetStatesContestants();
  }, []);

  useEffect(() => {
    console.log("APIdivisions", APIdivisions);
    if (APIdivisions.length) {
      // setDivisions(APIdivisions);
      const query = {
        division: APIdivisions[0],
        contestant_intake: contestant,
        page: 1,
      };
      if (state && !isNational) query["state"] = state;

      console.log("query", query);

      history.push(`/vote?${qs.stringify(query)}`);
    } else {
      if (state) showAlert("error", "This state has no pageants.");
    }
    setIsLoading(false);
  }, [APIdivisions]);

  const handleSubmit = () => {
    setIsLoading(true);
    asyncGetDivisions();
  };

  return (
    <Paper className={classes.root} elevation={4}>
      <Container maxWidth="md">
        <Box my={1}>
          <Typography variant="h5">Choose the Pageant and the State</Typography>
        </Box>
        <Box
          width="100%"
          style={{ minHeight: "12vh" }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Box my={1} width="100%">
            <Autocomplete
              fullWidth
              id="contestant-intake-auto-complete"
              options={statesContestants.contestant_intakes}
              getOptionLabel={(option) => option.friendly_name}
              // style={{ width: 100 }}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setState("");
                  setIsLoading(0);
                  setContestant("");
                } else {
                  setIsNational(newValue.is_national);
                  setContestant(newValue.contestant_intake);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={contestant}
                  label="Pageant"
                  variant="outlined"
                />
              )}
            />
          </Box>
          {isNational === 0 && (
            <Box width="100%" my={1}>
              <Autocomplete
                fullWidth
                id="state-auto-complete"
                options={statesContestants.states}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  setState(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={state}
                    label="State"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </Container>
      <Box
        mt={1}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Link href="https://www.unitedstatescrown.com/terms" target="_blank">
            Terms &amp; Conditions
          </Link>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={
            (!state && !isNational) || (!contestant && isNational) || isLoading
          }
          onClick={handleSubmit}
          // onClick={() => {
          //
          // }}
        >
          Enter
          {isLoading && (
            <CircularProgress
              style={{ position: "absolute" }}
              color="primary"
              size={24}
            />
          )}
        </Button>
      </Box>
    </Paper>
  );
}
