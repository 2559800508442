import React, { useState } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import LeftArrowIcon from '@material-ui/icons/NavigateBefore';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SpeedDial from '@material-ui/lab/SpeedDial';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import qs from 'qs';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },

  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  primaryText: {
    color: '#3C4852',
    fontSize: '.95rem',
  },
  selectedItem: {
    color: '#3C4852',
    borderLeft: '5px solid #5d87bb82',
    fontWeight: 600,
    backgroundColor: '#fff !important',
  },
  filterTitle: {
    paddingLeft: '8px',
    color: '#3C4852',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  search: {
    border: '1px solid #e1e1e1e1',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    display: 'flex',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon

    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function FullScreenDialog({ divisions }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const queryStr = history.location.search;
  const query = qs.parse(queryStr.replace('?', ''));
  const [searchName, setSearchName] = useState(query.name);
  const selectedDivisionTitle = query.division;
  console.log('searchName', searchName);

  const clearSearch = async () => {
    await setSearchName('');
    const tempQuery = { ...query };
    delete tempQuery.name;
    history.push(
      `/vote?${qs.stringify({
        ...tempQuery,
        page: 1,
      })}`
    );
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SpeedDial
        ariaLabel='SpeedDial example'
        className={classes.speedDial}
        icon={<MenuIcon color='secondary' />}
        onClick={() => setOpen(true)}
        open={false}
        direction='up'
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Box
              width='100%'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='h5' className={classes.title}>
                Filter
              </Typography>
              <Button
                onClick={handleClose}
                color='secondary'
                variant='outlined'
              >
                CLose
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box mt={2} />
        <Typography variant='h5' className={classes.filterTitle}>
          Name
        </Typography>
        <Box mt={1} mb={2} px={2} borderRadius={5}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder='Search…'
              value={searchName}
              onChange={(event) => setSearchName(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setOpen(false);
                  history.push(
                    `/vote?${qs.stringify({
                      ...query,
                      name: searchName,
                      page: 1,
                    })}`
                  );
                }
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              endAdornment={
                <div className={classes.clearIcon} onClick={clearSearch}>
                  <ClearIcon />
                </div>
              }
            />
          </div>
        </Box>

        <Typography variant='h5' className={classes.filterTitle}>
          Divisions
        </Typography>
        <List>
          {divisions.length
            ? divisions.map((division) => (
                <ListItem
                  button
                  selected={selectedDivisionTitle === division}
                  classes={{ selected: classes.selectedItem }}
                  key={division.division_id}
                  onClick={() => {
                    history.push(
                      `/vote?${qs.stringify({
                        ...query,
                        division,
                        page: 1,
                      })}`
                    );
                    setOpen(false);
                  }}
                >
                  <ListItemText
                    primary={division}
                    classes={{
                      primary: classes.primaryText,
                    }}
                  />
                </ListItem>
              ))
            : [...Array(10)].map((item) => (
                <ListItem key={item}>
                  <Skeleton
                    height={35}
                    variant='rect'
                    style={{ width: '100%' }}
                  />
                </ListItem>
              ))}
        </List>
        <Box display='flex' justifyContent='flex-start' m={2}>
          <Button
            startIcon={<LeftArrowIcon />}
            variant='outlined'
            color='primary'
            onClick={() => history.push('/')}
          >
            Change Competition
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
