import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';
import { Box, Typography } from '@material-ui/core';
import { useWidth } from 'index';
import { Context as TimerContext } from 'context/TimerContext';
import qs from 'qs';
import moment from 'moment-timezone';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterBox: {
    textAlign: 'center',

    // width: '15%',
  },
  counter: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  text: {
    color: '#3C4852',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 1,
  },
}));
let interval = null;
export default function CountDown({ queryStr, setEnableVote }) {
  const classes = useStyles();
  const width = useWidth();
  const { getTimer, state } = useContext(TimerContext);
  const [timer, setTimer] = useState({});
  const [total, setTotal] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isHidden, setIsHidden] = useState(true);

  const asyncGetTimer = async () => {
    const query = qs.parse(queryStr.replace('?', ''));
    const result = await getTimer(
      query.state,
      query.contestant_intake,
      query.division
    );
    if (result) {
      if (result.error) {
        if (result.msg) {
        }
      } else {
      }
    }
  };

  useEffect(() => {
    setIsHidden(true);
    asyncGetTimer();
  }, [queryStr]);

  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(getTimeRemaining, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setTimer(state);
    // setIsHidden(false);
  }, [state]);

  const getTimeRemaining = () => {
    const endTimeWithTz = moment.tz(
      timer.end_time,
      process.env.REACT_APP_COUNTDOWN_TIMEZONE
    );
    const total = endTimeWithTz.diff(moment());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    if (total <= 0) {
      setTotal(0);
      setDays('00');
      setHours('00');
      setMinutes('00');
      setSeconds('00');
      setIsHidden(true);
      setEnableVote(false);
    } else {
      setTotal(total);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
      setIsHidden(false);
      setEnableVote(true);
    }
  };

  return (
    <>
      {/* {!isHidden && ( */}
      <div
        className={classes.container}
        id='clockdiv'
        style={{
          visibility: isHidden ? 'hidden' : 'unset',
          justifyContent: ['xs', 'sm'].includes(width) ? 'center' : 'flex-end',
        }}
      >
        <Box className={classes.counterBox}>
          <Typography color='secondary' className={classes.counter}>
            {days}
          </Typography>

          <Typography variant='subtitle1' className={classes.text}>
            Days
          </Typography>
        </Box>
        <Box>
          <Typography variant='h6'>:</Typography>
        </Box>
        <Box className={classes.counterBox}>
          <Typography color='secondary' className={classes.counter}>
            {hours}
          </Typography>

          <Typography variant='subtitle1' className={classes.text}>
            Hours
          </Typography>
        </Box>
        <Box>
          <Typography variant='h6'>:</Typography>
        </Box>
        <Box className={classes.counterBox}>
          <Typography color='secondary' className={classes.counter}>
            {minutes}
          </Typography>

          <Typography variant='subtitle1' className={classes.text}>
            Minutes
          </Typography>
        </Box>
        <Box>
          <Typography variant='h6'>:</Typography>
        </Box>
        <Box className={classes.counterBox}>
          <Typography color='secondary' className={classes.counter}>
            {seconds}
          </Typography>

          <Typography variant='subtitle1' className={classes.text}>
            Seconds
          </Typography>
        </Box>
      </div>
      {/* )} */}
    </>
  );
}
