import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import ModelInfo from 'components/ModelDialog/ModelInfo';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VoteIcon from '@material-ui/icons/HowToVote';
import { useTheme } from '@material-ui/core/styles';
import { useWidth } from 'index';
import PaymentForm from 'components/PaymentForm';
import axios from 'axios';
import VotingModal from 'components/VotingModal/VotingModal';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide({ model, setOpen, open, enableVote }) {
  // const [open, setOpen] = React.useState(false);
  const width = useWidth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [paymentToken, setPaymentToken] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleVote = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/vote?id=${model.contact_id}`
      );
      console.log('response', response);
      if (response) {
        setPaymentToken(response.data.token);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <PaymentForm token={paymentToken} />

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        maxWidth='xl'
        // keepMounted
        onClose={handleClose}
        aria-labelledby='model-dialog'
        aria-describedby='model-dialog-description'
        fullScreen={fullScreen}
      >
        <DialogContent
          style={{ width: fullScreen ? 'unset' : '60vw', padding: '10px' }}
        >
          <ModelInfo model={model} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color='primary'
            size='large'
            variant='text'
          >
            Close
          </Button>
          {enableVote && <VotingModal modelId={model.contact_id} />}
        </DialogActions>
      </Dialog>
    </div>
  );
}
