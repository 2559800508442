import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CountDown from 'components/CountDown';

import { Grid, Container, Box, Typography, Tooltip } from '@material-ui/core';
import { Context as DivisionsContext } from 'context/DivisionsContext';
import { Provider as ModelsProvider } from 'context/ModelsContext';
import { Context as ALertContext } from 'context/AlertContext';
import ModelsGallery from 'components/ModelsGallarey';
import { useWidth } from 'index';
import { useLocation, useParams } from 'react-router-dom';
import SideMenu from 'components/SideMenu';
import MobileMenu from 'components/MobileMenu';
import HeaderLogo from 'assets/images/header_logo.png';
import SpeedDial from '@material-ui/lab/SpeedDial';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  productsBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '2px solid black',
    // height: '15vh',
    backgroundColor: theme.palette.primary.light,
  },
  divisionTitle: {
    color: '#3C4852',
    fontWeight: 'bold',
  },
  bannerTitle: {
    textTransform: 'uppercase',
    color: '#3C4852',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function Home() {
  const width = useWidth();
  const history = useHistory();
  const classes = useStyles();
  const queryStr = useLocation().search;
  const { getDivisions, state: divisions } = useContext(DivisionsContext);
  const { showAlert } = useContext(ALertContext);
  const query = qs.parse(queryStr.replace('?', ''));
  const [state, setState] = useState(query.state);
  const [timer, setTimer] = useState({});
  const [contestant, setContestant] = useState(query.contestant_intake);
  const [divisionTitle, setDivisionTitle] = useState(
    query.division ? query.division : ''
  );
  const [enableVote, setEnableVote] = useState(false);

  async function asyncGetDivisions() {
    const result = await getDivisions(contestant, state);
    if (result) {
      if (result.error) {
        if (result.msg) {
          showAlert('error', result.msg);
        }
      } else {
        console.log('divisions', divisions);
      }
    }
  }

  useEffect(() => {
    asyncGetDivisions();
    // asyncGetTimer();
  }, []);

  useEffect(() => {
    setDivisionTitle(query.division);
  }, [queryStr]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <Tooltip title='Go To Top'>
        <SpeedDial
          ariaLabel='go-to-top-button'
          className={classes.speedDial}
          icon={<ExpandLessIcon />}
          open
          onClick={goToTop}
        />
      </Tooltip>
      <Box className={classes.productsBanner} py={1} mb={1}>
        <img height={90} src={HeaderLogo} alt='logo' />
        <Typography className={classes.bannerTitle} variant='h3'>
          PEOPLE'S CHOICE
        </Typography>
      </Box>

      <Container maxWidth='xl'>
        <Grid
          container
          direction={['xs', 'sm'].includes(width) ? 'column-reverse' : 'row'}
        >
          <Grid item xs={false} md={2}></Grid>
          <Grid item xs={12} md={10}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              flexWrap={{ xs: 'wrap', sm: 'unset' }}
            >
              <Box
                mt={1}
                width='100%'
                textAlign={{ xs: 'center', sm: 'start' }}
              >
                <Typography variant='h4' className={classes.divisionTitle}>
                  {divisionTitle}
                </Typography>
              </Box>
              <Box mt={1}>
                {/* {timer.end_time && ( */}
                <CountDown queryStr={queryStr} setEnableVote={setEnableVote} />
                {/* )} */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {!['xs', 'sm'].includes(width) ? (
            <Grid item xs={2}>
              <Box height='100%' width='100%'>
                <SideMenu divisions={divisions} />
              </Box>
            </Grid>
          ) : (
            <MobileMenu divisions={divisions} />
          )}
          <Grid container item xs={12} md={10}>
            <ModelsProvider>
              <ModelsGallery
                enableVote={enableVote}
                query={query}
                queryStr={queryStr}
                itemsPerRow={4}
              />
            </ModelsProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
