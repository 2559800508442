import createDataContext from './createDataContext';
import modelsApi from 'api/modelsApi';
import qs from 'qs';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const errCodeMapper = {
  404: 'Product was not found',
  401: 'Your session has expired, Sign in again!',
  666: 'Network Error, Try Again!',
};

const modelsReducer = (state, action) => {
  switch (action.type) {
    case 'get_models':
      return {
        data: action.payload.data,

        lastPage: action.payload.last_page,
      };
    case 'add_models':
      return {
        data: state.data.concat(action.payload.data),

        lastPage: action.payload.last_page,
      };
    case 'reset_models':
      return {
        data: [],
        lastPage: 1,
      };
    default:
      return state;
  }
};

const getModels = (dispatch) => {
  const result = {
    error: false,
    statusCode: 200,
    msg: '',
  };
  //   return async (query) => {
  return async (queryStr) => {
    try {
      source.cancel('Operation canceled by the user.');
      source = CancelToken.source();

      const query = qs.parse(queryStr.replace('?', ''));
      const response = await modelsApi.get(`contacts${queryStr}`, {
        cancelToken: source.token,
      });
      console.log('response', response);
      if (response) {
        if (response.data) {
          const resData = response.data;
          if (query.page === '1') {
            dispatch({
              type: 'get_models',
              payload: resData,
            });
          } else {
            dispatch({
              type: 'add_models',
              payload: resData,
            });
          }
        }
      }
      return result;
    } catch (err) {
      result.error = true;
      if (err.response) {
        const errCode = err.response.status;
        result.statusCode = errCode;
        result.msg = errCodeMapper[errCode];
      } else if (err.request) {
        result.statusCode = 666;
        result.msg = errCodeMapper[666];
      } else {
        result.msg = 'Unexpected Error';
        console.log('Unexpected Error Happened!');
      }
      return result;
    }
  };
};
const resetModelsState = (dispatch) => {
  return async () => {
    dispatch({
      type: 'reset_models',
      payload: {
        data: [],
        metaData: {},
      },
    });
  };
};

export const { Context, Provider } = createDataContext(
  modelsReducer,
  { getModels, resetModelsState },
  {
    data: [],
    lastPage: 1,
  }
);
