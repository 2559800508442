import React from 'react';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeaderLogo from 'assets/images/header_logo.png';
import CompetitionStepper from 'components/CompetitionStepper';
const useStyles = makeStyles((theme) => ({
  productsBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '2px solid black',
    // height: '15vh',
    backgroundColor: theme.palette.primary.light,
  },
  divisionTitle: {
    color: '#3C4852',
    fontWeight: 'bold',
  },
  bannerTitle: {
    textTransform: 'uppercase',
    color: '#3C4852',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function Home() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.productsBanner} py={1} mb={1}>
        <img height={90} src={HeaderLogo} alt='logo' />
        <Typography className={classes.bannerTitle} variant='h3'>
          PEOPLE'S CHOICE
        </Typography>
      </Box>
      <Box
        height='70vh'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Container maxWidth='sm'>
          <CompetitionStepper />
        </Container>
      </Box>
    </>
  );
}
