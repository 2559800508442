import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Vote from 'pages/Vote';
import Home from 'pages/Home';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NotFound from 'pages/NotFound';
import { Provider as DivisionsProvider } from 'context/DivisionsContext';
import { Provider as TimerProvider } from 'context/TimerContext';
import { Context as AlertContext } from 'context/AlertContext';

import { Provider as StateContestantProvider } from 'context/StateContestantContext';

export default function AppRouter() {
  const alertState = useContext(AlertContext).state;

  const { hideAlert } = useContext(AlertContext);

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/home' />
        </Route>
        <DivisionsProvider>
          <StateContestantProvider>
            <TimerProvider>
              <Route exat path='/home/'>
                <Home />
              </Route>
              <Route exat path='/vote/'>
                <Vote />
              </Route>
            </TimerProvider>
          </StateContestantProvider>
        </DivisionsProvider>
        <Route path='/users'></Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key='top,right'
        open={alertState.open}
        autoHideDuration={2500}
        onClose={hideAlert}
      >
        <Alert
          severity={alertState.type}
          variant='standard'
          style={{ fontSize: '18px', fontWeight: 500 }}
        >
          {alertState.msg}
        </Alert>
      </Snackbar>
    </Router>
  );
}
