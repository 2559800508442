import React, { useEffect, useState } from 'react';
import { fade, makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import LeftArrowIcon from '@material-ui/icons/NavigateBefore';

import ClearIcon from '@material-ui/icons/Close';

import { Box, Divider, InputBase, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(1),
    },
    sideMenu: {
      width: '100%',
    },
    title: {
      color: '#3C4852',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    primaryText: {
      fontSize: '.95rem',
      color: '#3C4852',
    },
    selectedItem: {
      color: '#3C4852',
      borderLeft: '5px solid #5d87bb82',
      fontWeight: '600 !important',
      backgroundColor: '#fff !important',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    clearIcon: {
      padding: theme.spacing(0, 1),
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      display: 'flex',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon

      paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
  })
);

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function InteractiveList({ divisions }) {
  const classes = useStyles();
  const history = useHistory();
  const queryStr = history.location.search;
  const query = qs.parse(queryStr.replace('?', ''));
  const [searchName, setSearchName] = useState(query.name);
  const selectedDivisionTitle = query.division;

  const handleSearch = (event) => {
    const value = event.target.value;
    console.log('value', value);
    setSearchName(value);
    const newQuery = {
      ...query,
      name: value,
      page: 1,
    };

    if (!value) delete newQuery.name;
    history.push(`/vote?${qs.stringify(newQuery)}`);
  };

  const clearSearch = async () => {
    await setSearchName('');
    const tempQuery = { ...query };
    delete tempQuery.name;
    history.push(
      `/vote?${qs.stringify({
        ...tempQuery,
        page: 1,
      })}`
    );
  };
  return (
    <Box className={classes.root}>
      <Typography variant='h5' className={classes.title}>
        Name
      </Typography>

      <Box mt={1} mb={3} border='1px solid #e1e1e1e1' borderRadius={5}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder='Search…'
            value={searchName}
            onChange={handleSearch}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            endAdornment={
              <div className={classes.clearIcon} onClick={clearSearch}>
                <ClearIcon />
              </div>
            }
          />
        </div>
      </Box>
      {divisions.length > 0 && (
        <Box>
          <Typography variant='h5' className={classes.title}>
            Divisions
          </Typography>

          <Box className={classes.sideMenu}>
            <List>
              {divisions.length
                ? divisions.map((division) => (
                    <ListItem
                      button
                      selected={selectedDivisionTitle === division}
                      classes={{ selected: classes.selectedItem }}
                      key={division}
                      onClick={() =>
                        history.push(
                          `/vote?${qs.stringify({
                            ...query,
                            division,
                            page: 1,
                          })}`
                        )
                      }
                    >
                      <ListItemText
                        classes={{
                          primary: classes.primaryText,
                        }}
                        primary={division}
                      />
                    </ListItem>
                  ))
                : [...Array(10)].map((item) => (
                    <ListItem key={item}>
                      <Skeleton
                        height={35}
                        variant='rect'
                        style={{ width: '100%' }}
                      />
                    </ListItem>
                  ))}
            </List>
          </Box>
        </Box>
      )}
      <Box display='flex' justifyContent='flex-start' my={2}>
        <Button
          startIcon={<LeftArrowIcon />}
          variant='outlined'
          color='primary'
          onClick={() => history.push('/')}
        >
          Change Competition
        </Button>
      </Box>
    </Box>
  );
}
