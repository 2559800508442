import createDataContext from './createDataContext';

const alertReducer = (state, action) => {
  switch (action.type) {
    case 'show_alert':
      return {
        open: true,
        type: action.payload.type,
        msg: action.payload.msg,
      };
    case 'hide_alert':
      return {
        open: false,
        type: 'info',
        msg: '',
      };
    default:
      return state;
  }
};

const showAlert = (dispatch) => {
  return async (type, msg) => {
    dispatch({ type: 'show_alert', payload: { type, msg } });
  };
};

const hideAlert = (dispatch) => {
  return async () => {
    dispatch({ type: 'hide_alert' });
  };
};

export const { Context, Provider } = createDataContext(
  alertReducer,
  { showAlert, hideAlert },
  { open: false, msg: '', type: '' }
);
