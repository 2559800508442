import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import QuestionsPanel from 'components/ModelDialog/QuestionsPanel';
import LocationIcon from '@material-ui/icons/Place';
import VoteIcon from '@material-ui/icons/HowToVoteOutlined';
import GroupIcon from '@material-ui/icons/Group';
import TitleIcon from '@material-ui/icons/Title';
import { Grid } from '@material-ui/core';
import { Box, Divider } from '@material-ui/core';
import { useWidth } from 'index';
import numeral from 'numeral';
const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // display: 'flex',
  },
  aboutContainer: {
    marginTop: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
  cover: {
    width: '100%',
    maxWidth: '100%',

    backgroundPosition: ' center !important',
  },
  info: {
    color: '#3C4852',
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 400,
  },
}));

export default function MediaControlCard({ model }) {
  const classes = useStyles();
  const width = useWidth();

  return (
    <Card className={classes.root} elevation={0}>
      <Grid container spacing={2}>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          md={5}
          lg={4}
          alignContent='flex-start'
        >
          <Grid item xs={6} md={12}>
            <img
              className={classes.cover}
              src={`${process.env.REACT_APP_API_ENDPOINT}/api/image/${
                model.contact_id
              }?q=${Math.random()}`}
              alt={model.name}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <Box>
              {/* <Box my={1}>
                <Typography variant='h5' style={{ color: '#3C4852' }}>
                  {model.name}
                </Typography>
              </Box> */}

              {/* <Typography variant='h6' className={classes.info}>
                <GroupIcon style={{ marginRight: '8px' }} /> {model.title}
              </Typography> */}
              {model.title && (
                <Typography variant='h6' className={classes.info}>
                  <TitleIcon style={{ marginRight: '8px' }} />
                  {model.title}
                </Typography>
              )}

              <Typography variant='h6' className={classes.info}>
                <GroupIcon style={{ marginRight: '8px' }} />
                {model.friendly_name}
              </Typography>

              <Typography variant='h6' className={classes.info}>
                <LocationIcon style={{ marginRight: '8px' }} /> {model.state}
              </Typography>

              <Typography variant='h6' className={classes.info}>
                <VoteIcon style={{ marginRight: '8px' }} />{' '}
                {`${numeral(model.total_votes).format('0,0')} votes`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={7} lg={8}>
          <Box className={classes.aboutContainer}>
            <Grid item xs={12}>
              <Box my={1}>
                <Typography variant='h3' style={{ color: '#3C4852' }}>
                  {model.name}
                </Typography>
              </Box>
              {model.about && (
                <>
                  <Box my={1}>
                    <Typography variant='h5' style={{ color: '#3C4852' }}>
                      About
                    </Typography>
                  </Box>
                  <Box my={1} px={1}>
                    <Typography variant='body1' style={{ color: '#3C4852' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: model.about,
                        }}
                      ></div>
                    </Typography>
                  </Box>
                </>
              )}

              {model.questions_answers && model.questions_answers.length !== 0 && (
                <Box mt={3} mb={1}>
                  <Typography variant='h5' style={{ color: '#3C4852' }}>
                    Questionnaire
                  </Typography>
                </Box>
              )}
              <Box my={1} p={1} style={{ overflow: 'auto' }}>
                <QuestionsPanel
                  questions={
                    model.questions_answers ? model.questions_answers : []
                  }
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
