import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, Typography, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '80vh',
    alignItems: 'center',
  },
  container: {
    height: '60%',
  },
  imageContainer: {
    maxWidth: '100%',
  },
  oops: {
    marginTop: '32px',
    marginBottom: '8px',
    fontFamily: 'TimeNewRomamsPS',
    letterSpacing: 1.4,
    fontWeight: 'bold',
  },
  pageNotFound: {
    fontFamily: 'TimeNewRomamsPS',
    letterSpacing: 1.5,
    fontWeight: 'bold',
  },
  text: {
    fontFamily: 'TimeNewRomamsPS',
    color: '#1f1f1f',
    opacity: 0.7,
    fontSize: '22px',
    marginTop: '22px',
    marginBottom: '22px',
  },
  btn: {
    padding: theme.spacing(1.2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: '16px',
  },
}));
export default function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container maxWidth='lg'>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.container}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-around',
              }}
            >
              <Typography color='primary' className={classes.oops} variant='h2'>
                Oops!
              </Typography>
              <Typography
                className={classes.pageNotFound}
                variant='h2'
                gutterBottom
              >
                Page Not Found
              </Typography>

              <Typography className={classes.text} gutterBottom>
                The link you followed may be broken, or the page may have been
                removed.
              </Typography>
              <Button
                className={classes.btn}
                onClick={() => history.push('/')}
                variant='contained'
                color='primary'
                size='large'
              >
                GO BACK HOME
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
