import createDataContext from './createDataContext';
import divisionsApi from 'api/divisionsApi';
import qs from 'qs';

const errCodeMapper = {
  404: 'Product was not found',
  401: 'Your session has expired, Sign in again!',
  666: 'Network Error, Try Again!',
};

const divisionsReducer = (state, action) => {
  switch (action.type) {
    case 'get_divisions':
      return action.payload.divisions ? action.payload.divisions : [];

    default:
      return state;
  }
};

const getDivisions = (dispatch) => {
  const result = {
    error: false,
    statusCode: 200,
    msg: '',
  };
  return async (contestant, state) => {
    try {
      const query = { contestant_intake: contestant };
      if (state) query['state'] = state;
      const querystr = qs.stringify(query);
      const response = await divisionsApi.get(`?${querystr}`, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}`,
        // },
      });
      if (response) {
        dispatch({ type: 'get_divisions', payload: response.data });
      }
      return result;
    } catch (err) {
      result.error = true;
      if (err.response) {
        const errCode = err.response.status;
        result.statusCode = errCode;
        result.msg = errCodeMapper[errCode];
      } else if (err.request) {
        result.statusCode = 666;
        result.msg = errCodeMapper[666];
      } else {
        result.msg = 'Unexpected Error';
        console.log('Unexpected Error Happened!');
      }
      return result;
    }
  };
};
export const { Context, Provider } = createDataContext(
  divisionsReducer,
  { getDivisions },
  []
);
