import React, { useEffect, useRef } from 'react';

export default function PaymentForm(props) {
  const token = props.token;
  const formRef = useRef(null);

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        formRef.current.submit();
      }, 500);
    }
  }, [token]);

  return (
    <form
      style={{ display: 'none' }}
      ref={formRef}
      method='post'
      action='https://accept.authorize.net/payment/payment'
      id='formAuthorizeNetProductionPage'
      name='formAuthorizeNetProductionPage'
    >
      <input type='hidden' id='redirectToken' name='token' value={token} />
    </form>
  );
}
