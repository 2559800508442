import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Router from 'router';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  useTheme,
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';

import { Provider as AlertProvider } from 'context/AlertContext';
import FranklinGothic from 'assets/fonts/FranklinGothic.TFF';
import { StylesProvider } from '@material-ui/core/styles';

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const franklinGothic = {
  fontFamily: 'FranklinGothic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('FranklinGothic'),
    local('FranklinGothic-Regular'),
    url(${FranklinGothic}) format('tff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
let theme = createMuiTheme({
  palette: {
    primary: {
      light: 'rgb(181,201,226)',
      main: 'rgb(94,135,187)',
      dark: 'rgb(49,71,96)',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(226,227,230)',
      contrastText: '#000',
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: 'FranklinGothic',
    body1: {
      fontSize: 17,
      fontWeight: 300,
    },
  },
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <AlertProvider>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Router />
      </StylesProvider>
    </ThemeProvider>
  </AlertProvider>,
  document.getElementById('root')
);
