import createDataContext from './createDataContext';
import modelsApi from 'api/modelsApi';

const errCodeMapper = {
  404: 'Product was not found',
  401: 'Your session has expired, Sign in again!',
  666: 'Network Error, Try Again!',
};

const modelReducer = (state, action) => {
  switch (action.type) {
    case 'get_model':
      return action.payload;

    default:
      return state;
  }
};

const getModelData = (dispatch) => {
  const result = {
    error: false,
    statusCode: 200,
    msg: '',
  };
  return async (id) => {
    try {
      const response = await modelsApi.get(`${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response) {
        dispatch({ type: 'get_model', payload: response.data.data });
      }
    } catch (err) {
      result.error = true;
      if (err.response) {
        const errCode = err.response.status;
        result.statusCode = errCode;
        result.msg = errCodeMapper[errCode];
      } else if (err.request) {
        result.statusCode = 666;
        result.msg = errCodeMapper[666];
      } else {
        result.msg = 'Unexpected Error';
        console.log('Unexpected Error Happened!');
      }
      return result;
    }
  };
};

export const { Context, Provider } = createDataContext(
  modelReducer,
  { getModelData },
  {}
);
