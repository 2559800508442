import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  questionsContainer: {
    borderRadius: 5,
    marginBottom: theme.spacing(1),
  },
}));

export default function SimpleExpansionPanel({ questions }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {questions.map((question, idx) => (
        <ExpansionPanel
          square={false}
          classes={{ root: classes.questionsContainer }}
          elevation={2}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='questions-content'
            id='questions-header'
          >
            <Typography
              variant='h6'
              color='primary'
            >{`Q: ${question.question}`}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box px={2}>
              <Typography variant='body1'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.answer,
                  }}
                ></div>
              </Typography>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}
