import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Context as ModelsContext } from 'context/ModelsContext';
import { Provider as ModelProvider } from 'context/ModelContext';
import { Context as AlertContext } from 'context/AlertContext';
import { useHistory } from 'react-router-dom';
import LeftArrowIcon from '@material-ui/icons/NavigateBefore';

import ModelCard from 'components/ModelsGallarey/ModelCard';
import { Skeleton } from '@material-ui/lab';
import { useWidth } from 'index';
import qs from 'qs';
import BottomScrollListener from 'react-bottom-scroll-listener';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    minHeight: '100vh',
    height: '100%',
    width: '100%',

    // minHeight: '80vh'
  },
}));
export default function ModelsGallery({
  query,
  itemsPerRow,
  queryStr,
  enableVote,
}) {
  const width = useWidth();
  const classes = useStyles();
  const history = useHistory();
  const { getModels, resetModelsState, state } = useContext(ModelsContext);
  const [models, setModels] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(Number(query.page));
  const { showAlert } = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = () => {
    const newPage = page + 1;
    // console.log('page', page);
    // console.log('newPage', newPage);
    if (Number(newPage) <= Number(lastPage)) {
      setPage(page + 1);
      history.push(`/vote?${qs.stringify({ ...query, page: newPage })}`);
    } else window.onscroll = null;
  };

  async function asyncGetModels() {
    setIsLoading(true);
    if (query.page === '1') {
      resetModelsState();
    }

    const result = await getModels(queryStr);

    if (result) {
      if (result.error) {
        if (result.msg) {
          showAlert('error', result.msg);
          setIsLoading(false);
        }
      } else {
        // window.onscroll = function () {
        //   if (
        //     window.innerHeight + window.scrollY >=
        //     document.body.offsetHeight - 350
        //   ) {
        //     window.onscroll = null;
        //     handlePageChange(null, page + 1);
        //   }
        // };
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    asyncGetModels();
    setPage(Number(query.page));
  }, [queryStr]);

  useEffect(() => {
    // if (state.data.length !== 0) {
    setModels(state.data);
    setLastPage(state.lastPage);
    // }
  }, [state]);

  return (
    <Box className={classes.root}>
      <BottomScrollListener onBottom={handlePageChange} offset={200} />
      <Grid
        container
        spacing={2}
        justify='flex-start'
        // style={{ height: '100%' }}
      >
        {/* {models.length !== 0 ? ( */}
        <>
          {models &&
            models.map((model, idx) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={12 / itemsPerRow}
                  key={model.id}
                >
                  <ModelProvider>
                    <ModelCard model={model} enableVote={enableVote} />
                  </ModelProvider>
                </Grid>
              );
            })}
          {isLoading
            ? [...Array(8)].map((item, idx) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={12 / itemsPerRow}
                    key={item}
                  >
                    <Skeleton
                      style={{
                        height: '100%',
                        minHeight: '55vh',
                      }}
                      variant='rect'
                    />
                  </Grid>
                );
              })
            : models &&
              models.length === 0 && (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  width='100%'
                  flexDirection='column'
                  height='30vh'
                >
                  <Box>
                    <Typography
                      gutterBottom
                      variant='h2'
                      style={{ fontSize: '25px' }}
                    >
                      No beauty pageants to display.
                    </Typography>
                  </Box>
                  <Box display='flex' justifyContent='flex-start' my={2}>
                    <Button
                      startIcon={<LeftArrowIcon />}
                      variant='outlined'
                      color='primary'
                      onClick={() => history.push('/')}
                    >
                      Change Competition
                    </Button>
                  </Box>
                </Box>
              )}
        </>
      </Grid>
    </Box>
  );
}
